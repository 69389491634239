import { Box, Typography } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid';
import { CustomDropdown } from 'components/elements/custom-dropdown/CustomDropdown';
import {
  FamilyHistorySummaryChart,
  LaboratoryResultSummaryChart,
  MedicationSummaryChart,
  PastMedicalHistorySummaryChart,
  ProcedureSummaryChart,
  SummaryChartBloodPressure,
  SummaryChartContentType,
  VitalSignsSummaryChart,
  VitalsSignsMeasurements,
} from 'node-api/scribe/scribe.types';
import { useEffect, useState } from 'react';
import { camelCaseToSentence } from 'utils/stringHelpers';
import { CustomTable } from '../CustomTable';
import { SectionProps } from './render-sections';

type VitalSignData = {
  measure: string;
} & {
  [date: string]:
    | number
    | SummaryChartBloodPressure
    | { averageDiastolic: number; averageSystolic: number }
    | null
    | string
    | keyof VitalsSignsMeasurements;
};

type LabTestResultsData = {
  measure: string;
} & {
  [date: string]: string | undefined;
};

type ProcedureData = {
  procedure: string;
  code?: string;
  date: string;
};

type MedicationData = {
  medication: string;
  dosage?: string;
  condition: string;
  startDate?: string;
  lastFilled: string;
};

type PastMedicalHistoryData = {
  condition: string;
  icd10Code: string;
  firstSeen: string;
  lastSeen: string;
};

type FamilyHistoryData = {
  member: string;
  condition: string;
  code: string;
  atAge: string;
  deceased: boolean;
};

export const SummaryCharts = ({
  content,
  stage,
  handlers,
}: SectionProps<SummaryChartContentType>) => {
  const [vitalsData, setVitalsData] = useState<VitalSignData[]>([]);
  const [laboratoryTestResultsData, setLaboratoryTestResultsData] = useState<LabTestResultsData[]>(
    []
  );
  const [medicationsData, setMedicationsData] = useState<MedicationData[]>([]);
  const [proceduresData, setProceduresData] = useState<ProcedureData[]>([]);
  const [pastMedicalHistoryData, setPastMedicalHistoryData] = useState<PastMedicalHistoryData[]>(
    []
  );
  const [familyHistoryData, setFamilyHistoryData] = useState<FamilyHistoryData[]>([]);

  const proceduresColumns: GridColDef[] = [
    {
      field: 'procedure',
      headerName: 'Procedure',
      flex: 1,
      minWidth: 150,
      sortable: false,
    },
    {
      field: 'code',
      headerName: 'Code',
      flex: 0.3,
      align: 'center',
      headerAlign: 'center',
      sortable: false,
    },
    {
      field: 'date',
      headerName: 'Date',
      flex: 0.3,
      align: 'center',
      headerAlign: 'center',
      sortable: false,
    },
  ];

  const medicationsColumns: GridColDef[] = [
    {
      field: 'medication',
      headerName: 'Medication',
      flex: 1,
      minWidth: 150,
      sortable: false,
    },
    {
      field: 'dosage',
      headerName: 'Dosage',
      flex: 0.3,
      align: 'center',
      headerAlign: 'center',
      sortable: false,
    },
    {
      field: 'condition',
      headerName: 'Condition',
      flex: 0.3,
      align: 'center',
      headerAlign: 'center',
      sortable: false,
    },
    {
      field: 'startDate',
      headerName: 'Start Date',
      flex: 0.3,
      align: 'center',
      headerAlign: 'center',
      sortable: false,
    },
    {
      field: 'lastFilled',
      headerName: 'Last Filled',
      flex: 0.3,
      align: 'center',
      headerAlign: 'center',
      sortable: false,
    },
  ];

  const vitalsColumns: GridColDef[] = [
    {
      field: 'measure',
      headerName: 'Measure',
      flex: 1,
      minWidth: 150,
      sortable: false,
    },
    ...Object.keys(content.data.vitals || {}).map(
      (date) =>
        ({
          field: `${date}`,
          headerName: `${date.replaceAll('-', '/')}`,
          flex: 0.3,
          align: 'center',
          headerAlign: 'center',
          sortable: false,
        } as GridColDef)
    ),
  ];

  const laboratoryTestResultsColumns: GridColDef[] = [
    {
      field: 'measure',
      headerName: 'Measure',
      flex: 1,
      minWidth: 150,
      sortable: false,
    },
    ...Object.keys(content.data.laboratoryTestResults || {}).map(
      (date) =>
        ({
          field: `${date}`,
          headerName: `${date.replaceAll('-', '/')}`,
          flex: 0.3,
          align: 'center',
          headerAlign: 'center',
          sortable: false,
        } as GridColDef)
    ),
  ];

  const pastMedicalHistoryColumns: GridColDef[] = [
    {
      field: 'condition',
      headerName: 'Condition',
      flex: 1,
      minWidth: 150,
      sortable: false,
    },
    {
      field: 'icd10Code',
      headerName: 'ICD10 Code',
      flex: 1,
      minWidth: 150,
      sortable: false,
    },
    {
      field: 'firstSeen',
      headerName: 'First Seen',
      flex: 1,
      minWidth: 150,
      sortable: false,
    },
    {
      field: 'lastSeen',
      headerName: 'Last Seen',
      flex: 1,
      minWidth: 150,
      sortable: false,
    },
  ];

  const familyHistoryColumns: GridColDef[] = [
    {
      field: 'member',
      headerName: 'Member',
      flex: 1,
      minWidth: 150,
      sortable: false,
    },
    {
      field: 'condition',
      headerName: 'Condition',
      flex: 1,
      minWidth: 150,
      sortable: false,
    },
    {
      field: 'code',
      headerName: 'Code',
      flex: 1,
      minWidth: 150,
      sortable: false,
    },
    {
      field: 'atAge',
      headerName: 'At Age',
      flex: 1,
      minWidth: 150,
      sortable: false,
    },
    {
      field: 'deceased',
      headerName: 'Deceased',
      flex: 1,
      minWidth: 150,
      sortable: false,
    },
  ];

  const convertVitalSigns = (vitals: VitalSignsSummaryChart) => {
    if (Object.keys(vitals).length === 0) {
      return [];
    }

    const firstMeasurement = Object.values(vitals)?.[0];

    const measurementKeys = Object.keys(
      firstMeasurement ?? {}
    ) as (keyof VitalsSignsMeasurements)[];

    const dates = Object.keys(vitals);

    return measurementKeys.map((measure) => {
      const data: VitalSignData = {
        measure: camelCaseToSentence(measure, ['InBody']),
      };

      dates.forEach((date) => {
        if (measure === 'bp') {
          data[date] = `${vitals[date][measure]?.systolic || '-'}/${
            vitals[date][measure]?.diastolic || '-'
          }`;
        } else if (measure === 'averageBp') {
          data[date] = `${vitals[date][measure]?.averageSystolic || '-'}/${
            vitals[date][measure]?.averageDiastolic || '-'
          }`;
        } else {
          data[date] = vitals[date][measure] || '-';
        }
      });

      return data;
    });
  };

  const convertLabTestResults = (labTestResults: LaboratoryResultSummaryChart) => {
    if (Object.keys(labTestResults).length === 0) {
      return [];
    }

    const resultMap = new Map<string, Record<string, string | undefined>>();

    for (const [date, labResults] of Object.entries(labTestResults)) {
      for (const labResult of labResults) {
        const key = labResult.interpretation || labResult.laboratoryTest?.name || 'Unknown Test';

        if (!resultMap.has(key)) {
          resultMap.set(key, { measure: key });
        } else {
          resultMap.get(key)![date] = labResult.value || '-';
        }
      }
    }

    return Array.from(resultMap.values()) as LabTestResultsData[];
  };

  const convertMedications = (medications: MedicationSummaryChart) => {
    return medications.map((med) => ({
      medication: med.associatedMedication?.conceptName || med.medication || 'Unknown Medication',
      dosage: med.dosage || '-',
      condition: med.mainCondition.name || '-',
      startDate: med.date || '-',
      lastFilled: '-',
    }));
  };

  const convertProcedures = (procedures: ProcedureSummaryChart) => {
    return procedures.map((p) => ({
      procedure: p.associatedProcedure?.name || p.procedure || 'Unknown Procedure',
      code: p.associatedProcedure?.cptCode || p.code || '-',
      date: p.date || '-',
    }));
  };

  const convertPastMedicalHistory = (pastMedicalHistory: PastMedicalHistorySummaryChart) => {
    return pastMedicalHistory.map((pmh) => ({
      condition: pmh.associatedCondition?.name || pmh.condition || 'Unknown Condition',
      icd10Code: pmh.associatedCondition?.icd10Code || pmh.code || '-',
      firstSeen: pmh.firstSeen || '-',
      lastSeen: pmh.lastSeen || '-',
    }));
  };

  const convertFamilyHistory = (familyHistory: FamilyHistorySummaryChart) => {
    return familyHistory.map((fh) => ({
      member: fh.familyMember || 'Unknown Member',
      condition: fh.conditions || fh.associatedConditions?.map((c) => c.name).join('\n') || '-',
      code: fh.code || '-',
      atAge: fh.date || '-',
      deceased: fh.deceased || false,
    }));
  };

  useEffect(() => {
    if (!content.data) {
      return;
    }

    const vitals = convertVitalSigns(content.data.vitals);
    const labTestResults = convertLabTestResults(content.data.laboratoryTestResults);
    const medications = convertMedications(content.data.medications);
    const procedures = convertProcedures(content.data.procedures);
    const familyHistory = convertFamilyHistory(content.data.familyHistory);
    const pastMedicalHistory = convertPastMedicalHistory(content.data.pastMedicalHistory);

    setVitalsData(vitals);
    setLaboratoryTestResultsData(labTestResults);
    setMedicationsData(medications);
    setProceduresData(procedures);
    setPastMedicalHistoryData(pastMedicalHistory);
    setFamilyHistoryData(familyHistory);
  }, []);

  return (
    <Box className='summary-charts'>
      <Typography mb={3}>Summary Charts</Typography>

      <Box mb={3}>
        <CustomDropdown title='Past Medical History' defaultExpanded>
          <CustomTable
            key={`${content.sectionId}-summary-charts-past-medical-history`}
            columns={pastMedicalHistoryColumns}
            data={pastMedicalHistoryData}
          />
        </CustomDropdown>
      </Box>

      <Box mb={3}>
        <CustomDropdown title='Family History' defaultExpanded>
          <CustomTable
            key={`${content.sectionId}-summary-charts-family-history`}
            columns={familyHistoryColumns}
            data={familyHistoryData}
          />
        </CustomDropdown>
      </Box>

      <Box mb={3}>
        <CustomDropdown title='Procedures' defaultExpanded>
          <CustomTable
            key={`${content.sectionId}-summary-charts-procedures`}
            columns={proceduresColumns}
            data={proceduresData}
          />
        </CustomDropdown>
      </Box>

      <Box mb={3}>
        <CustomDropdown title='Medications' defaultExpanded>
          <CustomTable
            key={`${content.sectionId}-summary-charts-medications`}
            columns={medicationsColumns}
            data={medicationsData}
          />
        </CustomDropdown>
      </Box>

      <Box mb={3}>
        <CustomDropdown title='Vitals' defaultExpanded>
          <CustomTable
            key={`${content.sectionId}-summary-charts-vitals`}
            columns={vitalsColumns}
            data={vitalsData}
          />
        </CustomDropdown>
      </Box>

      <Box mb={3}>
        <CustomDropdown title='Laboratory Historic' defaultExpanded>
          <CustomTable
            key={`${content.sectionId}-summary-charts-laboratory-test-results`}
            columns={laboratoryTestResultsColumns}
            data={laboratoryTestResultsData}
          />
        </CustomDropdown>
      </Box>
    </Box>
  );
};

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionDetails, AccordionSummary, Box, Typography } from '@mui/material';
import { ReactNode, useState } from 'react';
import './custom-dropdown.css';

type Props = {
  title: string;
  children: ReactNode | ReactNode[];
  defaultExpanded?: boolean;
};

export const CustomDropdown = ({ title, children, defaultExpanded = false }: Props) => {
  const [expanded, setExpanded] = useState<boolean>(defaultExpanded);

  return (
    <Accordion
      className='custom-accordion'
      expanded={expanded}
      onChange={() => setExpanded(!expanded)}>
      <AccordionSummary>
        <Box display='flex' alignItems='center' gap={1}>
          <ExpandMoreIcon />
          <Typography variant='h6'>{title}</Typography>
        </Box>
      </AccordionSummary>
      <AccordionDetails>{children}</AccordionDetails>
    </Accordion>
  );
};

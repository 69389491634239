import { Condition } from 'interfaces/health-data';
import {
  CPTCode,
  MDMCode,
  MedicalBehavioralProblem,
  MedicalProcedure,
  MeetingDurationRange,
} from './medical';

export enum MeetingSummaryStatus {
  ReadyForReview = 'READY_FOR_REVIEW',
  WaitingForSignature = 'WAITING_FOR_SIGNATURE',
  Signed = 'SIGNED',
}

export enum MeetingSummaryStage {
  PreVisit = 'PRE_VISIT',
  PostVisit = 'POST_VISIT',
}

export enum ScribeOutputType {
  MeetingDuration = 'meeting-duration',
  MeetingContext = 'meeting-context',
  MedicalProblems = 'medical-problems',
  MedicalRemainingProblems = 'medical-remaining-problems',
  DiagnosisSummary = 'diagnosis-summary',
  AppointmentSummary = 'appointment-summary',
  StaticText = 'static-text',
  SummaryCharts = 'summary-charts',
}

export enum MeetingContextLocation {
  Telehealth = 'Telehealth',
  InPerson = 'In-person',
  Unknown = 'Unknown',
}

export enum MeetingContextPatientGender {
  Male = 'Male',
  Female = 'Female',
  Other = 'Other',
  Unknown = 'Unknown',
}

export enum LaboratoryResultExternalDataSource {
  Labcorp = 'labcorp',
  LabQuest = 'lab-quest',
  DrChronoFax = 'drchrono-fax',
  StanfordPortal = 'stanford-portal',
  SutterPortal = 'sutter-portal',
  Metriport = 'metriport',
}

export interface MeetingContextOutput {
  patientDOB: string; // Date as string
  patientName: string;
  patientGender: MeetingContextPatientGender;
  appointmentLocation: MeetingContextLocation;
  mainProblemCode?: string;
  mainProblem?: string; // DEPRECATE THIS
}

export interface MeetingDurationOutput {
  cptCode: CPTCode;
  meetingDuration: string; // in minutes, as string
  meetingDurationRange: MeetingDurationRange;
}

export interface MedicalProblemOutput {
  name: string;
  history?: string;
  dataReview: DataReviewSection;
  icd10Code: string;
  subjectiveUpdates?: string;
  plan?: string;
  assessmentAndDiscussion?: string; // TODO: use this section
}

export interface MedicalRemainingProblemsOutput {
  mdmCode?: MDMCode;
  additionalProblem?: MedicalBehavioralProblem;
  medicalProcedures?: MedicalProcedure[]; // note: up to 5
}

export interface DiagnosisSummaryOutput {
  stableChronicDecease?: string[];
  minorOrSelfLimitedProblems?: string[];
  acuteUncomplicatedIllnessOrInjury?: string[];
  acuteComplicatedInjury?: string[];
  acuteIllnessWithSystemicSymptoms?: string[];
  acuteOrChronicIllnessOrInjury?: string[];
  chronicDiseaseOrTreatmentSideEffect?: string[];
  severeChronicDiseaseOrTreatmentSideEffect?: string[];
  newUndiagnosedProblem?: string[];
}

export interface AppointmentSummaryOutput {
  originatingSite: string; // default text as Patient’s home
  distantSite: string; // default text as clinic name
  startingTime: string; //  as date in the following format 12:00 pm
  endingTime: string; // as time in the following format 1:00 pm
}

export interface TextOutput {
  text: string;
}

export type MeetingSummaryResultsOutput =
  | MeetingContextOutput
  | MeetingDurationOutput
  | MedicalProblemOutput
  | MedicalRemainingProblemsOutput
  | DiagnosisSummaryOutput
  | AppointmentSummaryOutput
  | TextOutput;

export interface MeetingSummarySection {
  id: number;
  meetingSummaryId: number;
  meetingTemplateSectionId: number | null;
  resolvedPrompt: string;
  results: MeetingSummaryResultsOutput;
  outputType: ScribeOutputType;
  order: number;
  createdAt: string;
  updatedAt: string;
}

export type MeetingSummary = {
  id: number;
  meetingId: number;
  meetingTemplateId: number;
  signatureHistory?: {
    signedBy: {
      firstName: string;
      lastName: string;
      specialty: string;
    };
    signedOn: string;
  }[];
  stage: MeetingSummaryStage;
  status: MeetingSummaryStatus;
  createdAt: string;
  updatedAt: string;
};

export type ProviderNoteData = {
  provider: {
    firstName: string;
    lastName: string;
    specialty: string;
  };
};

export type NoteExcerpt = {
  meetingSummary: MeetingSummary;
  meeting: Meeting;
  meetingType: MeetingType;
};

export enum MeetingTypeStatus {
  ENABLED = 'ENABLED',
  DISABLED = 'DISABLED',
}

export enum MeetingStatus {
  PENDING = 'PENDING',
  TRANSCRIBING = 'TRANSCRIBING',
  COLLECTING_DATA = 'COLLECTING_DATA',
  SUMMARIZING = 'SUMMARIZING',
  SUMMARY_COMPLETED = 'SUMMARY_COMPLETED',
}

export enum PatientGender {
  Male = 'Male',
  Female = 'Female',
  Other = 'Other',
  Unknown = 'Unknown',
}

export interface ScheduledMeetingMetadata {
  appointmentType: string;
  clinicName: string;
  concatenatedPipelineId?: string;
  endAt: string;
  environment?: string;
  internalPatientBmi: number;
  internalPatientDOB: string;
  internalPatientFullName: string;
  internalPatientGender: PatientGender;
  internalPatientId: string;
  internalPatientUuid: string;
  internalProviderFirstName: string;
  internalProviderFullName: string;
  internalProviderId: string;
  internalProviderLastName: string;
  internalProviderUuid: string;
  pipelineId?: string;
  startAt: string;
}

export type Meeting = {
  id: number;
  meetingTypeId: number;
  internalProviderId: string;
  internalPatientId: string;
  internalAppointmentId: string;
  rawAppointment: {
    createdAt: string;
    externalSchedulingId: number;
    metadata: {
      appointmentType: string;
      clinicName: string;
      endAt: string;
      internalPatientBmi: number | null;
      internalPatientDOB: string;
      internalPatientFullName: string;
      internalPatientGender: string;
      internalPatientId: string;
      internalPatientUuid: string;
      internalProviderFirstName: string;
      internalProviderFullName: string;
      internalProviderId: string;
      internalProviderLastName: string;
      internalProviderUuid: string;
      startAt: string;
      syncAttempts: number;
    };
    updatedAt: string;
  };
  provider: {
    firstName: string;
    lastName: string;
    specialty: string;
  };
  status: MeetingStatus;
  createdAt: string;
  updatedAt: string;
  deletedAt: string | null;
};

export type MeetingType = {
  id: number;
  customerId: number;
  medicalSpecialtyId: number | null;
  meetingTemplateId: number;
  internalCode: string;
  status: MeetingTypeStatus;
  createdAt: string;
  updatedAt: string;
};

export interface SummaryChartBloodPressure {
  source: string;
  systolic: number;
  diastolic: number;
}

export type VitalsSignsMeasurements = {
  inBodyWeight: number | null;
  weight: number | null;
  bp: SummaryChartBloodPressure | null;
  pbf: number | null;
  smm: number | null;
  averageBp: {
    averageDiastolic: number;
    averageSystolic: number;
  } | null;
  averageRestingHr: number | null;
  averageFoodPicturesPerWeek: number | null;
  averageHomeBloodGlucose: number | null;
  averageStepsPerDay: number | null;
};

export type VitalSignsSummaryChart = {
  [date: string]: VitalsSignsMeasurements;
};

export interface PatientCondition {
  id: string;
  patientId: string;
  status: string | null;
  code: string | null;
  conditionId: number | null;
  condition: string;
  firstSeen: string | null;
  lastSeen: string | null;
  deleteAt: string | null;
}

export interface PatientLaboratoryResult {
  id: string;
  observation?: string;
  value?: string;
  interpretation?: string;
  referenceRange?: string;
  codeType?: string;
  code?: string;
  externalDataSource: string;
  excluded: boolean;
}

export interface PatientLaboratoryResultGroup {
  id: string;
  patientId: string;
  date?: string;
  type?: string;
  externalDataSource: LaboratoryResultExternalDataSource | null;
  patientLaboratoryResults: PatientLaboratoryResult[];
}

export interface PatientProcedure {
  status: string | null;
  id: string;
  patientId: string;
  code: string | null;
  date: string | null;
  procedure: string | null;
  createdAt: string;
  updatedAt: string;
}
export interface PatientSocialHistory {
  id: string;
  patientId: string;
  code: string | null;
  date: string | null;
  observation: string;
  value: string | null;
  createdAt: string;
  updatedAt: string;
}

export interface PatientVitalSign {
  id: string;
  patientId: string;
  code: string | null;
  date: string | null;
  observation: string;
  value: string | null;
  createdAt: string;
  updatedAt: string;
}

export interface PatientAllergy {
  status: string | null;
  id: string;
  patientId: string;
  code: string | null;
  description: string;
  firstSeen: string | null;
  lastSeen: string | null;
  manifestation: string;
  createdAt: string;
  updatedAt: string;
}

export interface PatientAuthor {
  id: string;
  name: string;
  authoredOn?: string;
  createdAt: string;
  updatedAt: string;
}

export interface PatientMedication {
  id: string;
  patientId: string;
  medication: string;
  instructions?: string;
  dosage?: string;
  status?: PatientMedicationStatus;
  code?: string;
  date?: string;
  excluded: boolean;
  createdAt: string;
  updatedAt: string;
}

export interface PatientEncounter {
  id: string;
  patientId: string;
  encounter?: string;
  location?: string;
  category?: string;
  startDate?: string;
  endDate?: string;
  notes: PatientEncounterNote[];
  createdAt: string;
  updatedAt: string;
}

export interface PatientEncounterNote {
  id: string;
  encounterId: string;
  author?: string;
  facility?: string;
  noteDate?: string;
  rawDetails?: string;
  createdAt: string;
  updatedAt: string;
}

export interface PatientFamilyMemberHistory {
  id: string;
  createdAt: string;
  updatedAt: string;
  code: string | null;
  patientId: string;
  conditions: string | null;
  date: string | null;
  deceased: boolean | null;
  familyMember: string;
  gender: string | null;
}

export enum ProcedureStatus {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
}

export interface Procedure {
  name: string;
  id: number;
  status: ProcedureStatus;
  createdAt: string;
  updatedAt: string;
  cptCode: string;
}

export interface Medication {
  id: number;
  status: PatientMedicationStatus;
  createdAt: string;
  updatedAt: string;
  conceptName: string;
  ndcCode: string;
  rxCuiCode: string;
}

export interface LaboratoryTest {
  id: number;
  name: string;
  status: LaboratoryTestStatus;
  units: string[];
  createdAt: string;
  updatedAt: string;
  loincCode: string;
}

export enum LaboratoryTestStatus {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
}

export type LaboratoryResultSummaryChart = {
  [date: string]: (PatientLaboratoryResult & { laboratoryTest?: LaboratoryTest })[];
};

export type MedicationSummaryChart = (PatientMedication & {
  associatedMedication?: Medication;
  mainCondition: Condition;
})[];

export type ProcedureSummaryChart = (PatientProcedure & { associatedProcedure?: Procedure })[];

export type FamilyHistorySummaryChart = (PatientFamilyMemberHistory & {
  associatedConditions?: Condition[];
})[];

export type PastMedicalHistorySummaryChart = (PatientCondition & {
  associatedCondition?: Condition;
})[];

export type ComputedSummaryCharts = {
  vitals: VitalSignsSummaryChart;
  laboratoryTestResults: LaboratoryResultSummaryChart;
  medications: MedicationSummaryChart;
  procedures: ProcedureSummaryChart;
  pastMedicalHistory: PastMedicalHistorySummaryChart;
  familyHistory: FamilyHistorySummaryChart;
};
export interface MeetingContextContentType {
  sectionId: string;
  order: number;
  outputType: ScribeOutputType.MeetingContext;
  data: MeetingContextOutput;
}

export interface MeetingDurationContentType {
  sectionId: string;
  order: number;
  outputType: ScribeOutputType.MeetingDuration;
  data: MeetingDurationOutput;
}

export interface MedicalProblemsContentType {
  sectionId: string;
  order: number;
  outputType: ScribeOutputType.MedicalProblems;
  data: MedicalProblemOutput[];
}

export interface MedicalRemainingProblemsContentType {
  sectionId: string;
  order: number;
  outputType: ScribeOutputType.MedicalRemainingProblems;
  data: MedicalRemainingProblemsOutput;
}

export interface DiagnosisSummaryContentType {
  sectionId: string;
  order: number;
  outputType: ScribeOutputType.DiagnosisSummary;
  data: DiagnosisSummaryOutput;
}

export interface AppointmentSummaryContentType {
  sectionId: string;
  order: number;
  outputType: ScribeOutputType.AppointmentSummary;
  data: AppointmentSummaryOutput;
}

export interface StaticTextContentType {
  sectionId: string;
  order: number;
  outputType: ScribeOutputType.StaticText;
  data: TextOutput;
}

export interface SummaryChartContentType {
  sectionId: string;
  order: number;
  outputType: ScribeOutputType.SummaryCharts;
  data: ComputedSummaryCharts;
}

export type SummaryContentType =
  | DiagnosisSummaryContentType
  | MedicalProblemsContentType
  | MedicalRemainingProblemsContentType
  | MeetingDurationContentType
  | MeetingContextContentType
  | AppointmentSummaryContentType
  | StaticTextContentType
  | SummaryChartContentType;

export type NoteSectionsWithStatus = {
  content?: SummaryContentType[];
};

export type UpdateSummaryResponse = {
  status: boolean;
  note?: NoteDetail;
};

export type SignSummaryResponse = {
  status: boolean;
  note?: Pick<NoteDetail, 'meetingSummary'> & { status: MeetingSummaryStatus };
};

export enum PatientMedicationStatus {
  Active = 'active',
  Stopped = 'stopped',
}
export interface GetBriefSummariesByDateResponse {
  [date: string]: BriefSummary[];
}

export type BriefSummary = {
  summaryId: number;
  status: MeetingSummaryStatus;
  meetingId: number;
  externalSchedulingId: number | null;
  stage: MeetingSummaryStage;
};

export type EntityWithSummaryInfo<T> = T &
  Partial<{
    meetingStatus: MeetingSummaryStatus;
    meetingId: number;
  }>;

export type GetAllSummariesPayload = {
  internalProviderId?: string;
  externalProviderId?: string;
  internalPatientId: string;
  specialties?: string;
  page?: number;
  month: string;
  pageSize?: number;
  status?: MeetingSummaryStatus;
};

// NOTE: [time, label, content]
export type MeetingTranscriptionDialogLine = [string, string, string];

export interface MeetingTranscriptionDialog {
  providerConfig: { token: string; value: string };
  patientConfig: { token: string; value: string };
  lines: MeetingTranscriptionDialogLine[];
}

export interface MeetingTranscriptionDialogWithTimeRanges extends MeetingTranscriptionDialog {
  timeRanges: [number, number][]; // [from, until] in seconds
}

export type NoteDetail = {
  meeting: Meeting;
  meetingType: MeetingType;
  meetingSummary: MeetingSummary;
  date: string;
  provider: {
    firstName: string;
    lastName: string;
    specialty: string;
  };
  meetingRecordingUrl: string | null;
  meetingTranscriptionDialog: MeetingTranscriptionDialog | null;
} & NoteSectionsWithStatus;

export type NoteDetailWithTranscription = NoteDetail & {
  meetingTranscriptionDialog: MeetingTranscriptionDialogWithTimeRanges | null;
};

export type NoteExcerptWithProvider = NoteExcerpt & ProviderNoteData;

export type GetAllSummariesByDateResponse = {
  data: { [date: string]: NoteExcerptWithProvider[] };
};

export const EvaluationAndManagementByCPTCode: {
  [cptCode: number]: {
    meetingDurationRange: MeetingDurationRange;
  };
} = {
  99212: {
    meetingDurationRange: MeetingDurationRange.TenToNineteen,
  },
  99213: {
    meetingDurationRange: MeetingDurationRange.TwentyToTwentyNine,
  },
  99214: {
    meetingDurationRange: MeetingDurationRange.ThirtyToThirtyNine,
  },
  99215: {
    meetingDurationRange: MeetingDurationRange.FortyToSixtyFixe,
  },
};

export type DataReviewPatientMedication = Omit<PatientMedication, 'id' | 'patientId'> & {
  isTaking: boolean;
};

export type DataReviewPatientLabResult = Omit<PatientLaboratoryResult, 'id' | 'patientId'> & {
  resultDate: string;
};

export type DataReviewSection = {
  vitals: PatientVitalSign[];
  labs: DataReviewPatientLabResult[];
  medications: DataReviewPatientMedication[];
  exams: Omit<PatientProcedure, 'id' | 'patientId'>[];
};

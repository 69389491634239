export const transformStringWithSpacesToKebab = (text: string) =>
  text.toLowerCase().split(' ').join('-');

export const camelCaseToSentence = (text: string, excludeWords: string[] = []) => {
  if (text.includes(' ')) {
    return text;
  }

  return text.replace(/([a-z])([A-Z])/g, '$1 $2').replace(/^./, (char) => char.toUpperCase());
};
